
import { defineComponent, ref } from 'vue';
import Footer from '../../components/Frontend/Footer.vue';
import SocialMediaIconBox from '../../components/Frontend/SocialMediaIconBox.vue';
import { mdiChevronUp, mdiFormatQuoteClose } from '@mdi/js';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  meta: {
    de: {
      title: 'Kontakt | Styria Media Group',
      keywords: ['kontakt', 'kontaktformular', 'email', 'mail adresse', 'kontaktieren', 'styria media group'],
      description: 'Wir freuen uns auf Sie! Mail-Adresse, Telefonnummer, Kontaktformular',
      image: require('@/assets/img/og/startseite.png'),
    },
    en: {
      title: 'Contact | Styria Media Group',
      keywords: ['contact', 'contact form', 'email', 'mail adresse', 'styria media group'],
      description: 'We look forward to hearing from you! Mail address, telephone number, contact form.',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const firstname = ref('');
    const surname = ref('');
    const text = ref('');
    const email = ref('');
    const dsvgo = ref(false);
    const error = ref('');
    const path = `${store.getters.getHomeBase}datenschutz_${i18n.locale.value}.html`;
    const sent = ref(false);

    const send = () => {
      error.value = '';

      if (!text.value || text.value == '') {
        error.value = i18n.t('General.formerror-firstname');
        return;
      }

      if (!surname.value || surname.value == '') {
        error.value = i18n.t('General.formerror-surname');
        return;
      }
      if (!email.value || email.value == '') {
        error.value = i18n.t('General.formerror-email');
        return;
      }
      if (!text.value || text.value == '') {
        error.value = i18n.t('General.formerror-text');
        return;
      }
      if (error.value == '') {
        store
          .dispatch('sendMail', {
            type: 'contact',
            locale: i18n.locale.value,
            data: {
              firstname: firstname.value,
              surname: surname.value,
              text: text.value,
              email: email.value,
            },
          })
          .then(() => {
            sent.value = true;
          });
      }
      return;
    };

    return {
      sent,
      firstname,
      surname,
      text,
      email,
      error,
      send,
      mdiChevronUp,
      mdiFormatQuoteClose,
      path,
    };
  },
});
